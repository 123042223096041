var render = function render(){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"citation-labels small"},[_vm._t("label-slot"),_c('span',{staticClass:"labels"},[(_vm.pubType && _vm.pubType.toLowerCase().includes('review'))?_c('span',{staticClass:"review"},[_vm._v("Review")]):_vm._e(),(_vm.citation.source === 'PPR')?_c('span',{staticClass:"preprint",attrs:{"id":'citation--tag--preprint-' + _vm.citation.id}},[_vm._v("Preprint\n      "+_vm._s(_vm.citation.versionNumber ? 'v' + _vm.citation.versionNumber : ''))]):_vm._e(),(_vm.citation.hasBook === 'Y')?_c('span',{staticClass:"books-and-documents"},[_vm._v("Books & documents")]):_vm._e(),(_vm.citation.source === 'PAT')?_c('span',{staticClass:"patent"},[_vm._v("Patent")]):_vm._e(),(_vm.citation.isOpenAccess === 'Y' && _vm.citation.inEPMC === 'Y')?_c('tooltip',{attrs:{"placement":"top"}},[_c('span',{staticClass:"open-access-icon",attrs:{"slot":"trigger"},slot:"trigger"},[(_vm.citation.isOpenAccess === 'Y' && _vm.citation.inEPMC === 'Y')?_c('img',{attrs:{"src":require("@/assets/open_access_logo_white_blue.svg")}}):_vm._e()]),_vm._v("\n      This article is in the\n      "),_c('a',{attrs:{"href":"https://europepmc.org/downloads/openaccess"}},[_vm._v("Europe PMC Open access subset")]),_vm._v(". Refer to the copyright information in the article for licensing\n      details.\n    ")]):_vm._e(),(
        (_vm.citation.isOpenAccess === 'Y' && _vm.citation.inEPMC === 'Y') ||
        _vm.citation.inEPMC === 'Y' ||
        _vm.citation.hasBook === 'Y' ||
        _vm.citation.source === 'NBK'
      )?_c('span',{class:_vm.citation.isOpenAccess === 'Y' && _vm.citation.inEPMC === 'Y'
          ? 'open-access'
          : 'fulltext'},[(_vm.page === 'Article')?[_vm._v(_vm._s(_vm.citation.isOpenAccess === 'Y' && _vm.citation.inEPMC === 'Y'
            ? 'Free full text in Europe PMC'
            : 'Free full text in Europe PMC')+" ")]:_c('router-link',{attrs:{"id":'citation--tag--free-text-' + _vm.citation.id,"to":{
          name: 'article',
          params: {
            source: _vm.citation.source,
            id: _vm.citation.id,
            matomo_event: {
              category: _vm.page,
              action: 'Citation',
              name: 'Full text link',
            },
          },
          hash: '#free-full-text',
        }}},[_vm._v("\n        "+_vm._s(_vm.citation.isOpenAccess === 'Y' && _vm.citation.inEPMC === 'Y'
            ? 'Free full text in Europe PMC'
            : 'Free full text in Europe PMC'))])],2):_vm._e(),_c('span',{staticClass:"last-slot"},[_vm._t("last-slot")],2)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }