var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.citation.unstructuredInformation)?_c('div',{class:_vm.areLinksDisabled ? 'citation-no-link' : 'citation'},[(_vm.citation.match !== 'N' && !_vm.areLinksDisabled)?_c(_vm.headingLevel,{tag:"component",staticClass:"citation-title",attrs:{"id":'citation--article--title-' + _vm.citation.id}},[_c('router-link',{attrs:{"to":{
        name: 'article',
        params: { source: _vm.citation.source, id: _vm.citation.id },
      }},domProps:{"innerHTML":_vm._s(_vm.title)}})],1):_c(_vm.headingLevel,{tag:"component",staticClass:"citation-title",domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.authorList)?_c('p',{staticClass:"citation-author-list"},[_vm._l((_vm.authorList.slice(0, _vm.authorNumber - 1)),function(author,index){return [(author !== 'AUTHOR UNKNOWN' && !_vm.areLinksDisabled)?_c('router-link',{key:index,attrs:{"to":{
          ..._vm.$options.filters.getAuthorLink(author),
          params: {
            matomo_event: {
              category: 'Search',
              action: 'Citation',
              name: 'Author name',
            },
          },
        }}},[_vm._v("\n        "+_vm._s(author))]):_vm._e(),_vm._v(_vm._s(author === 'AUTHOR UNKNOWN' || _vm.areLinksDisabled ? author : '')+_vm._s(index !== _vm.authorList.length - 1 ? _vm.authSeparator : '')+"\n    ")]}),(_vm.authorList.length > _vm.authorNumber && !_vm.areLinksDisabled)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.authorNumber = _vm.authorList.length}}},[_vm._v("\n      [...]\n    ")]):_vm._e(),_vm._v("\n    "+_vm._s(_vm.authorList.length > _vm.authorNumber && _vm.areLinksDisabled ? '[...]' : '')+"\n    "),(_vm.authorList.length >= _vm.authorNumber && !_vm.areLinksDisabled)?_c('router-link',{attrs:{"to":_vm._f("getAuthorLink")(_vm.authorList[_vm.authorList.length - 1])}},[_vm._v("\n      "+_vm._s(_vm.authorList[_vm.authorList.length - 1])+"\n    ")]):_vm._e(),_vm._v("\n    "+_vm._s(_vm.authorList.length >= _vm.authorNumber && _vm.areLinksDisabled
        ? _vm.authorList[_vm.authorList.length - 1]
        : '')+"\n  ")],2):_vm._e(),_c('p',[(_vm.publisherDetails && !_vm.areLinksDisabled)?_c('router-link',{attrs:{"id":'citation--publisher--name-' + _vm.citation.id,"to":{
        ..._vm.sourceLink,
        params: {
          matomo_event: {
            category: 'Search',
            action: 'Citation',
            name: 'Journal name',
          },
        },
      }}},[_vm._v("\n      "+_vm._s(_vm.publisherDetails))]):_vm._e(),_vm._v(_vm._s(_vm.publisherDetails && _vm.areLinksDisabled ? _vm.publisherDetails : '')+_vm._s(!_vm.citation.journalTitle && _vm.citation.journalAbbreviation
        ? _vm.citation.journalAbbreviation
        : '')+_vm._s((_vm.citation.journalVolume || _vm.citation.issue || _vm.citation.pageInfo) &&
      !['PAT', 'CBA'].includes(_vm.citation.source)
        ? ', '
        : '')+_vm._s(_vm.citation.journalVolume || '')+_vm._s(_vm.citation.issue ? '(' + _vm.citation.issue + ')' : '')+_vm._s((_vm.citation.journalVolume || _vm.citation.issue) && _vm.citation.pageInfo
        ? ':'
        : '')+_vm._s(_vm.citation.pageInfo ? _vm.citation.pageInfo : '')+_vm._s(_vm.citation.firstPublicationDate && _vm.citation.source !== 'PAT' ? ', ' : ' ')+_vm._s(_vm.citation.source === 'PAT' ? '[Application on: ' : ' ')+"\n    "),(_vm.citation.firstPublicationDate || _vm.citation.pubYear)?_c('span',{attrs:{"id":'citation--id--pub-date-' + _vm.citation.id}},[_vm._v("\n      "+_vm._s(_vm.getDate(_vm.citation.firstPublicationDate) || _vm.citation.pubYear)+_vm._s(_vm.citation.source === 'PAT' ? ']' : '')+"\n    ")]):_vm._e()],1),_vm._t("middle-slot"),_c('p',[(
        _vm.citation.citedByCount !== undefined &&
        !_vm.areLinksDisabled &&
        _vm.citation.citedByCount !== 0
      )?_c('router-link',{attrs:{"id":'citation--cited--by-' + _vm.citation.id,"to":_vm.citation.citedByCount > 1
          ? {
              name: 'search',
              query: {
                query: 'cites:' + _vm.citation.id + '_' + _vm.citation.source,
              },
            }
          : {
              name: 'article',
              params: {
                id: _vm.citation.id,
                source: _vm.citation.source,
              },
            }}},[_vm._v("\n      "+_vm._s(_vm.citedByText)+"\n    ")]):_vm._e(),_vm._v("\n    "+_vm._s(_vm.citation.citedByCount !== undefined &&
      (_vm.areLinksDisabled || _vm.citation.citedByCount === 0)
        ? _vm.citedByText
        : '')+"\n\n    "+_vm._s(_vm.citation.citedByCount !== undefined ? ' | ' : '')+"\n    "),(_vm.citation.pmid)?_c('span',{attrs:{"id":'citation--id--pmid-' + _vm.citation.id}},[_vm._v("PMID: "+_vm._s(_vm.citation.pmid))]):(_vm.isNotPmc)?[_vm._v("\n      "+_vm._s(_vm.citation.pmid ? ' | ' : '')+_vm._s(_vm.citation.source + ': ' + _vm.citation.id)+"\n    ")]:_vm._e(),(_vm.citation.pmcid)?_c('span',{attrs:{"id":'citation--id--pmc-' + _vm.citation.id}},[_vm._v(_vm._s(_vm.citation.pmid || _vm.isNotPmc ? ' | ' : '')+"PMCID:\n      "+_vm._s(_vm.citation.pmcid))]):_vm._e()],2),(!_vm.areLinksDisabled)?_c('labels',{attrs:{"citation":_vm.citation}},[_c('template',{slot:"label-slot"},[_vm._t("label-slot")],2)],2):_vm._e()],2):_c('h5',{staticClass:"citation"},[_vm._v("\n  "+_vm._s(_vm.citation.unstructuredInformation.replace(/[0-9]+\?[0-9]+/, (matched) =>
      matched.replace(/\?/, '-')
    ))+"\n")])
}
var staticRenderFns = []

export { render, staticRenderFns }