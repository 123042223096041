<script>
import { Tooltip } from 'epmc-patterns/components/v2'
export default {
  components: {
    Tooltip,
  },
  props: {
    citation: {
      type: Object,
      default: () => {},
    },
    page: {
      type: String,
      default: 'Search',
    },
  },
  computed: {
    pubType() {
      if (this.citation.pubTypeList && this.citation.pubTypeList.pubType) {
        return this.citation.pubTypeList.pubType.toString()
      }
      return this.citation.pubType
    },
  },
}
</script>
<template>
  <p class="citation-labels small">
    <slot name="label-slot" />
    <span class="labels">
      <span
        v-if="pubType && pubType.toLowerCase().includes('review')"
        class="review"
        >Review</span
      >
      <span
        v-if="citation.source === 'PPR'"
        :id="'citation--tag--preprint-' + citation.id"
        class="preprint"
        >Preprint
        {{ citation.versionNumber ? 'v' + citation.versionNumber : '' }}</span
      >
      <span v-if="citation.hasBook === 'Y'" class="books-and-documents"
        >Books & documents</span
      >
      <span v-if="citation.source === 'PAT'" class="patent">Patent</span>
      <tooltip
        v-if="citation.isOpenAccess === 'Y' && citation.inEPMC === 'Y'"
        placement="top"
      >
        <span slot="trigger" class="open-access-icon"
          ><img
            v-if="citation.isOpenAccess === 'Y' && citation.inEPMC === 'Y'"
            src="@/assets/open_access_logo_white_blue.svg"
        /></span>
        This article is in the
        <a href="https://europepmc.org/downloads/openaccess"
          >Europe PMC Open access subset</a
        >. Refer to the copyright information in the article for licensing
        details.
      </tooltip>
      <span
        v-if="
          (citation.isOpenAccess === 'Y' && citation.inEPMC === 'Y') ||
          citation.inEPMC === 'Y' ||
          citation.hasBook === 'Y' ||
          citation.source === 'NBK'
        "
        :class="
          citation.isOpenAccess === 'Y' && citation.inEPMC === 'Y'
            ? 'open-access'
            : 'fulltext'
        "
        ><template v-if="page === 'Article'"
          >{{
            citation.isOpenAccess === 'Y' && citation.inEPMC === 'Y'
              ? 'Free full text in Europe PMC'
              : 'Free full text in Europe PMC'
          }} </template
        ><router-link
          v-else
          :id="'citation--tag--free-text-' + citation.id"
          :to="{
            name: 'article',
            params: {
              source: citation.source,
              id: citation.id,
              matomo_event: {
                category: page,
                action: 'Citation',
                name: 'Full text link',
              },
            },
            hash: '#free-full-text',
          }"
        >
          {{
            citation.isOpenAccess === 'Y' && citation.inEPMC === 'Y'
              ? 'Free full text in Europe PMC'
              : 'Free full text in Europe PMC'
          }}</router-link
        ></span
      >
      <span class="last-slot">
        <slot name="last-slot" />
      </span>
    </span>
  </p>
</template>
<style lang="scss">
.citation-labels {
  .labels {
    display: flex;
  }
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  & > *:first-child:not(.labels) {
    margin-right: $base-unit * 2;
    @media screen and (max-width: $breakpoint-small) {
      margin-top: $base-unit * 2;
    }
  }
  .labels > span:not(.last-slot) {
    display: inline-block;
    //padding: 0 ($base-unit * 2);
    margin-top: $base-unit;
    @media screen and (max-width: $breakpoint-small) {
      margin-top: $base-unit * 2;
    }
    & + span {
      //margin-left: $base-unit * 2;
    }
    &.open-access {
      background-color: $epmc-med-blue;
      padding: 0 8px;
    }
    &.preprint {
      background-color: $epmc-light-green;
      margin-right: 10px;
      padding: 0 8px;
    }
    &.review {
      background-color: $epmc-lemongrass;
      margin-right: 10px;
      padding: 0 8px;
    }
    &.books-and-documents {
      background-color: $epmc-orange;
      margin-right: 10px;
      padding: 0 8px;
    }
    &.patent {
      background-color: lighten($epmc-darker-grey, 50%);
      margin-right: 10px;
      padding: 0 8px;
    }
    &.fulltext {
      background-color: lighten($epmc-dark-blue, 32.6%);
      margin-right: 10px;
      padding: 0 8px;
    }
  }
}
.open-access-icon {
  margin-right: $base-unit * 1;
}
</style>
