<script>
import { getDate } from 'epmc-patterns/helpers'
import Labels from '@/templates/Labels'

export default {
  filters: {
    getAuthorLink(author) {
      return {
        name: 'search',
        query: {
          query: 'AUTH:"' + author + '"',
        },
      }
    },
  },
  components: { Labels },
  props: {
    citation: {
      type: Object,
      default: () => {},
    },
    headingLevel: {
      type: String,
      default: 'h3',
    },
    areLinksDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authorNumber: 20,
    }
  },
  computed: {
    title() {
      const title =
        this.citation.title && this.citation.title.trim()
          ? this.citation.title
          : '<i>Title not supplied</i>'
      return title
    },
    authSeparator() {
      return this.citation.authorString.match(/;/g) ? ';' : ','
    },
    authorList() {
      if (this.citation.authorString) {
        return this.citation.authorString.match(/;/g)
          ? this.citation.authorString.replace(/\.$/, '').split('; ')
          : this.citation.authorString.replace(/\.$/, '').split(', ')
      }
      return ''
    },
    publisherDetails() {
      const { citation } = this
      let details =
        (citation.bookOrReportDetails &&
          citation.bookOrReportDetails.publisher) ||
        ''
      details = details || citation.journalTitle
      return details
    },
    sourceLink() {
      if (this.citation.journalTitle) {
        return {
          name: 'search',
          query: { query: 'JOURNAL:"' + this.citation.journalTitle + '"' },
        }
      } else if (this.citation.bookOrReportDetails) {
        return {
          name: 'search',
          query: {
            query:
              'PUBLISHER:"' +
              (this.citation.bookOrReportDetails.publisher ||
                this.citation.bookOrReportDetails.comprisingTitle) +
              '"',
          },
        }
      }
      return ''
    },
    citedByText() {
      const { citation } = this
      return (
        'Cited by: ' +
        citation.citedByCount +
        ' ' +
        (citation.citedByCount === 1 ? ' article' : ' articles')
      )
    },
    isNotPmc() {
      const { citation } = this
      return citation.source && citation.source !== 'PMC'
    },
  },
  methods: {
    getDate,
  },
}
</script>
<template>
  <div
    v-if="!citation.unstructuredInformation"
    :class="areLinksDisabled ? 'citation-no-link' : 'citation'"
  >
    <component
      :is="headingLevel"
      v-if="citation.match !== 'N' && !areLinksDisabled"
      :id="'citation--article--title-' + citation.id"
      class="citation-title"
    >
      <router-link
        :to="{
          name: 'article',
          params: { source: citation.source, id: citation.id },
        }"
        v-html="title"
      >
      </router-link>
    </component>
    <component
      :is="headingLevel"
      v-else
      class="citation-title"
      v-html="title"
    ></component>

    <p v-if="authorList" class="citation-author-list">
      <template
        v-for="(author, index) in authorList.slice(0, authorNumber - 1)"
      >
        <router-link
          v-if="author !== 'AUTHOR UNKNOWN' && !areLinksDisabled"
          :key="index"
          :to="{
            ...$options.filters.getAuthorLink(author),
            params: {
              matomo_event: {
                category: 'Search',
                action: 'Citation',
                name: 'Author name',
              },
            },
          }"
        >
          {{ author }}</router-link
        >{{ author === 'AUTHOR UNKNOWN' || areLinksDisabled ? author : ''
        }}{{ index !== authorList.length - 1 ? authSeparator : '' }}
      </template>
      <a
        v-if="authorList.length > authorNumber && !areLinksDisabled"
        href="#"
        @click.prevent="authorNumber = authorList.length"
      >
        [...]
      </a>
      {{ authorList.length > authorNumber && areLinksDisabled ? '[...]' : '' }}
      <router-link
        v-if="authorList.length >= authorNumber && !areLinksDisabled"
        :to="authorList[authorList.length - 1] | getAuthorLink"
      >
        {{ authorList[authorList.length - 1] }}
      </router-link>
      {{
        authorList.length >= authorNumber && areLinksDisabled
          ? authorList[authorList.length - 1]
          : ''
      }}
    </p>
    <p>
      <router-link
        v-if="publisherDetails && !areLinksDisabled"
        :id="'citation--publisher--name-' + citation.id"
        :to="{
          ...sourceLink,
          params: {
            matomo_event: {
              category: 'Search',
              action: 'Citation',
              name: 'Journal name',
            },
          },
        }"
      >
        {{ publisherDetails }}</router-link
      >{{ publisherDetails && areLinksDisabled ? publisherDetails : ''
      }}{{
        !citation.journalTitle && citation.journalAbbreviation
          ? citation.journalAbbreviation
          : ''
      }}{{
        (citation.journalVolume || citation.issue || citation.pageInfo) &&
        !['PAT', 'CBA'].includes(citation.source)
          ? ', '
          : ''
      }}{{ citation.journalVolume || ''
      }}{{ citation.issue ? '(' + citation.issue + ')' : ''
      }}{{
        (citation.journalVolume || citation.issue) && citation.pageInfo
          ? ':'
          : ''
      }}{{ citation.pageInfo ? citation.pageInfo : ''
      }}{{
        citation.firstPublicationDate && citation.source !== 'PAT' ? ', ' : ' '
      }}{{ citation.source === 'PAT' ? '[Application on: ' : ' ' }}
      <span
        v-if="citation.firstPublicationDate || citation.pubYear"
        :id="'citation--id--pub-date-' + citation.id"
      >
        {{ getDate(citation.firstPublicationDate) || citation.pubYear
        }}{{ citation.source === 'PAT' ? ']' : '' }}
      </span>
    </p>

    <slot name="middle-slot" />

    <p>
      <router-link
        v-if="
          citation.citedByCount !== undefined &&
          !areLinksDisabled &&
          citation.citedByCount !== 0
        "
        :id="'citation--cited--by-' + citation.id"
        :to="
          citation.citedByCount > 1
            ? {
                name: 'search',
                query: {
                  query: 'cites:' + citation.id + '_' + citation.source,
                },
              }
            : {
                name: 'article',
                params: {
                  id: citation.id,
                  source: citation.source,
                },
              }
        "
      >
        {{ citedByText }}
      </router-link>
      {{
        citation.citedByCount !== undefined &&
        (areLinksDisabled || citation.citedByCount === 0)
          ? citedByText
          : ''
      }}

      {{ citation.citedByCount !== undefined ? ' | ' : '' }}
      <span v-if="citation.pmid" :id="'citation--id--pmid-' + citation.id"
        >PMID: {{ citation.pmid }}</span
      >
      <template v-else-if="isNotPmc">
        {{ citation.pmid ? ' | ' : ''
        }}{{ citation.source + ': ' + citation.id }}
      </template>
      <span v-if="citation.pmcid" :id="'citation--id--pmc-' + citation.id"
        >{{ citation.pmid || isNotPmc ? ' | ' : '' }}PMCID:
        {{ citation.pmcid }}</span
      >
    </p>
    <labels v-if="!areLinksDisabled" :citation="citation">
      <template slot="label-slot">
        <slot name="label-slot" />
      </template>
    </labels>
  </div>
  <h5 v-else class="citation">
    {{
      citation.unstructuredInformation.replace(/[0-9]+\?[0-9]+/, (matched) =>
        matched.replace(/\?/, '-')
      )
    }}
  </h5>
</template>
<style scoped lang="scss">
.citation {
  word-wrap: break-word;
  .citation-title,
  .citation-author-list {
    margin: 0 auto;
    font-size: $base-unit * 4;
    line-height: $base-unit * 6;
  }
  p {
    margin: 0 auto;
  }
  .citation-author-list {
    color: $epmc-dark-green;
    a {
      color: $epmc-dark-green;
      &:hover {
        color: $epmc-med-green;
      }
      &:active {
        color: $epmc-darker-blue;
      }
      &:visited {
        color: $epmc-purple;
      }
    }
  }
}
.citation-no-link {
  color: $epmc-darker-grey !important;
  .citation-title,
  .citation-author-list {
    margin: 0 auto;
    font-size: $base-unit * 4;
    line-height: $base-unit * 6;
  }
  p {
    margin: 0 auto;
  }
}
</style>
